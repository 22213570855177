<template>
  <div>
    <a-spin tip="資料處理中..." :spinning="iconLoading">
      <a-button
        type="primary"
        :disabled="selectedRows.length==0"
        @click="enterView"
      ><b-icon style="margin-right: 0.3rem;" scale="1.1" icon="bookmark-check" variant="muted" />
      選取活動</a-button>
      <a-table
        :columns="columns"
        :row-key="record => record.uid"
        :data-source="list"
        :pagination="{ pageSize: 10 }"
        :row-selection="{ selectedRowKeys: selectedRowKeys,selectedRows:selectedRows, onChange: onSelectChange }"
        :scroll="{ y: 380 }"
      >
        <template slot="checked" slot-scope="record">
          <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="people" variant="muted" />
          <span v-text="record.checked?'會員':'非會員'"></span>
        </template>
        <template slot="created_at" slot-scope="record">
          <span v-text="fdateformat(record.created_at)"></span>
        </template>
      </a-table>

      <b-modal
        ref="my-list"
        id="modal-tall"
        title="選項"
        @hide="onHide"
        centered
        hide-footer
        hide-header
      >
        <div class="text-right" style="max-height: 720px;overflow-y:hidden;">
            <a-button @click="closeModal()"  :disabled="iconLoading2" size="small">關閉</a-button>
            <div style="padding:0.5rem;margin:0.3rem;">
                <Viewper :item="selectedRows" id="style-3" ref="con3" />
            </div>
        </div>
      </b-modal>
    </a-spin>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { userService } from "../../../_services/";
import Viewper from "../panel/Viewper.vue";
import * as moment from "moment/moment";

const queryData = params => {
  return userService.getMylist(params);
};


const columns = [
  {
    title: "會員",
    dataIndex: "",
    scopedSlots: { customRender: "checked" },
    width: 100
  },
  {
    title: "顯示名稱",
    dataIndex: "username",
    width: 150
  },   
  {
    title: "帳號",
    dataIndex: "account",
    width: 150
  },
  {
    title: "地址",
    dataIndex: "address"
  },
  {
    title: "電話",
    dataIndex: "phone"
  },  
  {
    title: "建立日期",
    dataIndex: "",
    scopedSlots: { customRender: "created_at" },
    width: 200
  }
];

export default {
  name: "MymenberApply",
  components: {
    Viewper
  },
  data() {
    return {
      list: [],
      rsData: [],
      uId: "",
      iconLoading: false,
      iconLoading2: false,
      columns,
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  mounted() {
    //this.reload()
  },
  watch: {
    defaultval() {
      this.reload();
    }
  },  
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas", "defaultval"]),
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  },

  methods: {
    reload() {
      this.fetch();
    },

    fetch(
      params = {
        id: this.defaultval.id
      }
    ) {
      this.iconLoading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        let obj = data;
        if (obj != null) {
          setTimeout(() => {
            this.list = obj;
            this.iconLoading = false;
          }, 600);

        } else {
          this.iconLoading = false;
        }
      });
    },
    

    onSelectChange(selectedRowKeys, selectedRows) {
      //console.log('selectedRowKeys changed: ', selectedRowKeys,selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    enterView() {
      this.$refs["my-list"].show();
    },
    closeModal() {
      this.$refs["my-list"].hide();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    }
  }
};
</script>
<style scoped>
.qrbody {
  padding: 0.4rem;
  color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  border-color: #3e3e3e;
  border-width: 0.35rem;
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}

#style-3 {
  text-align: left;
}
</style>