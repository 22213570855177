<template>
  <div>
    <a-spin tip="資料處理中..." :spinning="iconLoading">
      <a-table
        :columns="columns"
        :row-key="record => record.uid"
        :data-source="list"
        :pagination="pagination"
        @change="handleTableChange"
        :scroll="{ y: 480 }"
      >
        <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <a-list item-layout="horizontal" :data-source="record.acts_todlist">
                <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta
                    :description="'活動日期 : '+ item.sdate +'~'+item.edate +' 參加者 :'+ item.name +'('+item.username+')'+' 分數: '+ item.score"
                >
                    <span slot="title">{{ item.actitle }}</span>
                    <a-avatar
                    slot="avatar"
                    :src="userPng"
                    />
                </a-list-item-meta>
                </a-list-item>
            </a-list>            
        </p>
        <template slot="actitle" slot-scope="record">
          <span v-text="record.acts_todlist[0].actitle.substring(0,15)+' ...'"></span>
        </template>        
        <template slot="created_at" slot-scope="record">
          <span v-text="fdateformat(record.created_at)"></span>
        </template>
      </a-table>

      <b-modal
        ref="my-list"
        id="modal-tall"
        title="選項"
        @hide="onHide"
        centered
        hide-footer
        hide-header
      >
        <div class="text-right" style="max-height: 520px;overflow-y:hidden;">
            <a-button @click="closeModal()"  :disabled="iconLoading2" size="small">關閉</a-button>
            <div style="padding:0.5rem;margin:0.3rem;">
                
            </div>
        </div>
      </b-modal>
    </a-spin>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { joinService } from "../../../_services/";
import userPng from '../../../assets/man.png';
import * as moment from "moment/moment";

const queryData = params => {
  return joinService.getformm(params);
};


const columns = [
{
    title: "活動名稱",
    dataIndex: "",
    scopedSlots: { customRender: "actitle" },
    width: 150
  },  
  {
    title: "顯示名稱",
    dataIndex: "username",
    width: 150
  },    
  {
    title: "帳號",
    dataIndex: "name",
    width: 150
  },
  {
    title: "建立日期",
    dataIndex: "",
    scopedSlots: { customRender: "created_at" },
    width: 200
  }
];

export default {
  name: "MymenberApplyRecord",
  components: {
  },
  data() {
    return {
      list: [],
      uId: "",
      userPng,
      iconLoading: false,
      iconLoading2: false,
      columns,
      pagination: {defaultPageSize:4},      
    };
  },
  mounted() {
    //this.reload()
  },
  watch: {
    defaultval() {
      this.reload();
    }
  },  
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas", "defaultval"]),
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  },

  methods: {
    reload() {
      this.fetch({page:1,id:this.defaultval.id});
    },

    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      //console.log(this.pagination);
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        id: this.defaultval.id,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },    

    fetch(
        params = {}
    ) {
      this.iconLoading = true;
      queryData({
        ...params,
      }).then(( data ) => {
          setTimeout(() => {
            const pagination = { ...this.pagination };
            pagination.total = data.info.totail;
            this.iconLoading = false;
            this.list = data.results;
            this.pagination = pagination; 
          }, 600);          

      });
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    enterView() {
      this.$refs["my-list"].show();
    },
    closeModal() {
      this.$refs["my-list"].hide();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    }
  }
};
</script>
<style scoped>
.qrbody {
  padding: 0.4rem;
  color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  border-color: #3e3e3e;
  border-width: 0.35rem;
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}

#style-3 {
  text-align: left;
}
</style>