<template>
  <div class="containers">
    <div v-if="JSON.stringify(obj)!=='{}'">
      <div class="text-left">
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane key="1">
            <span slot="tab">
              <b-icon scale="1.1" icon="person-plus" variant="muted"></b-icon> 註冊新會員
            </span>
            <Inputmenber @done="done" ref="con1" />
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <b-icon scale="1.1" icon="receipt" variant="muted"></b-icon> 註冊清單
            </span>
            <Mymenber ref="con2" />
          </a-tab-pane>
          <a-tab-pane key="3">
            <span slot="tab">
              <b-icon scale="1.1" icon="people" variant="muted"></b-icon> 團體報名
            </span>
            <MymenberApply ref="con3" />
          </a-tab-pane>        
          <a-tab-pane key="4">
            <span slot="tab">
              <b-icon scale="1.1" icon="clock-history" variant="people"></b-icon> 報名紀錄
            </span>
            <MymenberApplyRecord ref="con4" />
          </a-tab-pane> 
          <a-tab-pane key="5">
            <span slot="tab">
              <b-icon scale="1.1" icon="person-circle" variant="people"></b-icon> 我的志工
            </span>
            <MyGroup ref="con5" />
          </a-tab-pane>                              
        </a-tabs>
      </div>
    </div>
    <b-modal
      ref="my-loading"
      id="modal-2"
      title="Loading"
      @hide="onHide"
      centered
      hide-footer
      hide-header
    >
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <strong>Loading....</strong>
      </div>
    </b-modal>

    <b-alert
      v-model="showTop"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
    >更新成功</b-alert>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Inputmenber from "../edit/panel/Inputmenber";
import Mymenber from "../edit/panel/Mymenber";
import MymenberApply from "../edit/panel/MymenberApply";
import MymenberApplyRecord from "../edit/panel/MymenberApplyRecord";
import MyGroup from "../edit/panel/MyGroup";

export default {
  name: "Men_generate",
  components: {
    Inputmenber,
    Mymenber,
    MymenberApply,
    MymenberApplyRecord,
    MyGroup,
  },

  data() {
    return {
      obj: {},
      rsdata: null,
      showTop: false,
      loading: false
    };
  },
  mounted() {
    this.obj = this.datas.find(item => item.id == this.defaultval.id);
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas", "defaultval"])
  },
  watch: {
    defaultval(val) {
      this.obj = this.datas.find(item => item.id == val.id);
    }
  },
  methods: {
    done() {
      this.$nextTick( ()=> {
            this.$refs.con2.reload()
          }) 
    },
    onReset(event) {
      event.preventDefault();
      this.$nextTick(() => {});
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },
    callback(key) {
      if(key==2 || key==3){
          this.$nextTick( ()=> {            
            key==2? this.$refs.con2.reload(): this.$refs.con3.reload()
          })          
      }else if(key==4){
        this.$nextTick( ()=> {            
            key==4? this.$refs.con4.reload(): ''
          })     
      }else if(key==5){
        this.$nextTick( ()=> {            
            key==4? this.$refs.con5.myReload(): ''
          })     
      }
    }
  }
};
</script>
<style scoped>
.containers {
  padding: 1.5rem;
}
</style>


