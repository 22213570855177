<template>
  <div>
    <a-spin tip="資料處理中..." :spinning="iconLoading">
      <a-button
        type="primary"
        :disabled="selectedRows.length==0"
        @click="enterView"
      >
      <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="printer" variant="muted" />
      列印
    </a-button> <span style="color:red" v-if="selectedRows.length>0" v-text="' 您選擇的數量 : '+selectedRows.length +' (一次列印筆數勿超過 200 組QR Code)'" />
      <a-table
        :columns="columns"
        :row-key="record => record.uid"
        :data-source="list"
        :pagination="{ pageSize: 10 }"
        :row-selection="{ selectedRowKeys: selectedRowKeys,selectedRows:selectedRows, onChange: onSelectChange }"
        :scroll="{ y: 380 }"
      >
        <template slot="checked" slot-scope="record">
          <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="people" variant="muted" />
          <span v-text="record.checked?'會員':'非會員'"></span>
        </template>
        <template slot="created_at" slot-scope="record">
          <span v-text="fdateformat(record.created_at)"></span>
        </template>
      </a-table>

      <b-modal
        ref="my-list"
        id="modal-2"
        title="選項"
        size="lg"
        @hide="onHide"
        scrollable
        centered
        hide-footer
        hide-header
      >
        <div class="text-center">
          <a-button @click="closeModal()" type="link" :disabled="iconLoading2" size="small">(關閉)</a-button>
          <a-button
            type="primary"
            :disabled="selectedRows.length==0 && selectedRows.length<=200"
            :loading="iconLoading2"
            @click="exportToPDF"
          >
          <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="printer" variant="muted" />
          列印</a-button>
          <div class="classempy" id="word1head">
            <h6>
              <strong>
                <span v-text="defaultval.name + ', 會員QR Code 輸出共 :' + selectedRows.length +' 組QR Code'"></span>
              </strong>
            </h6>
          </div>
          <div id="word123">
            <div
              class="list-group-item"
              :id="'word' + element.uid"
              style="padding:0.5rem;margin:2px;"
              v-for="element in selectedRows"
              :key="element.uid"
            >
              <b-container class="container">
                <b-row>
                  <b-col style="padding:0;">
                    <div class="text-center">
                      <span v-text="element.username +'|'+element.account " />
                      <div class="qrbody">
                        <vue-qr
                          ref="qrCode"
                          :text="'actjoin:'+element.uid"
                          :logoSrc="logoPath"
                          :logoScale="20"
                          :size="250"
                          :margin="10"
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col style="padding:0;text-align:left;">
                    <div style="display: flex;height: 300px;align-items: center; /* ★ */">
                      <strong>
                        <span
                          style="padding:1px;margin:1px;font-size: 16px;"
                          v-text="'顯示名稱 : ' + element.username"
                        />
                        <br />
                        <span
                          style="padding:1px;margin:1px;font-size: 16px;"
                          v-text="'帳號 : ' + element.account"
                        />
                        <br />
                        <span
                          style="padding:1px;margin:1px;font-size: 16px;"
                          v-text="'地址 : ' + element.address"
                        />
                        <br />
                        <span
                          style="padding:1px;margin:1px;font-size: 16px;"
                          v-text="'電話 : ' + element.phone"
                        />
                        <br />
                        <span
                          style="padding:1px;margin:1px;font-size: 16px;"
                          v-text="'建立日期 : ' + fdateformat(element.created_at) "
                        />
                        <br />
                        <span
                          style="padding:1px;margin:1px;font-size: 16px;"
                          v-text="'初始密碼 : ' + element.password "
                        />
                      </strong>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </b-modal>
    </a-spin>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { userService } from "../../../_services/";
import * as moment from "moment/moment";
import VueQr from "vue-qr";
import logoImg from "@/assets/lineicon.svg";
import html2pdf from "html2pdf.js";

const queryData = params => {
  return userService.getMylist(params);
};

const columns = [
  {
    title: "會員",
    dataIndex: "",
    scopedSlots: { customRender: "checked" },
    width: 100
  },
  {
    title: "顯示名稱",
    dataIndex: "username",
    width: 150
  },
  {
    title: "帳號",
    dataIndex: "account",
    width: 150
  },
  {
    title: "地址",
    dataIndex: "address"
  },
  {
    title: "電話",
    dataIndex: "phone"
  },
  {
    title: "建立日期",
    dataIndex: "",
    scopedSlots: { customRender: "created_at" },
    width: 200
  }
];

export default {
  name: "Mymenber",
  components: {
    VueQr
  },
  data() {
    return {
      list: [],
      iconLoading: false,
      iconLoading2: false,
      columns,
      selectedRowKeys: [],
      selectedRows: [],
      logoPath: logoImg
    };
  },
  mounted() {
    //this.reload()
    // Avoid page-breaks on all elements, and add one before #page2el.
    html2pdf().set({
      pagebreak: { mode: "avoid-all", before: "#page2el" }
    });

    // Enable all 'modes', with no explicit elements.
    html2pdf().set({
      pagebreak: { mode: ["avoid-all", "css", "legacy"] }
    });

    // No modes, only explicit elements.
    html2pdf().set({
      pagebreak: {
        before: ".beforeClass",
        after: ["#after1", "#after2"],
        avoid: "img"
      }
    });
  },
  watch: {
    defaultval() {
      this.reload();
    }
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas", "defaultval"]),
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  },

  methods: {
    reload() {
      this.fetch();
    },

    fetch(
      params = {
        id: this.defaultval.id
      }
    ) {
      this.iconLoading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        let obj = data;
        if (obj != null) {
          setTimeout(() => {
            this.list = obj;
            this.iconLoading = false;
          }, 600);
        } else {
          this.iconLoading = false;
        }
      });
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      //console.log('selectedRowKeys changed: ', selectedRowKeys,selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    enterView() {
      this.$refs["my-list"].show();
    },
    closeModal() {
      this.$refs["my-list"].hide();
    },
    groupBy(list, keyGetter) {
      //group by
      const map = new Map();
      list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },

    exportToPDF() {
      // pdf匯出設定
      this.iconLoading2 = true;
      this.iconLoading = true;
      const opt = {
        margin: 0.3,
        filename: "QrCode_member.pdf",
        image: { type: "jpeg", quality: 0.99 },
        //pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1", after: "1cm" },
        html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
      };
      this.domList = [];
      // 抓取各個圖片的DOM元素，並把它裝入doc這個陣列裡
      let newDiv = document.createElement("div");
      //let j = this.items.act_detrimal.length
      let head1 = document.getElementById("word1head");
      const clone1 = head1.cloneNode(true);
      newDiv.appendChild(clone1);

      //let element1 = document.getElementById("word123");

      let tmp = [];
      for (let item in this.selectedRows) {
        let element1 = document.getElementById(
          "word" + this.selectedRows[item].uid
        );
        const i = parseInt(item / 3);
        let obj = {
          id: i,
          el: element1
        };
        tmp.push(obj);
      }
      const grouped = this.groupBy(tmp, pet => pet.id); //Group by
      grouped.forEach((rs) => {
        let iDiv = document.createElement("div");
        rs.forEach((cs)=>{
          const clone1 = cs.el.cloneNode(true);
          iDiv.appendChild(clone1);
          //console.log(cs.el,cs.id)
        })
        this.domList.push(iDiv);
      });
      let iDiv2 = document.createElement("div");
      this.domList.push(iDiv2);
      //console.log(this.domList)
      if (this.domList.length > 0) {
        // 開始進行列印
        let doc = html2pdf()
          .from(this.domList[0])
          .set(opt)
          .toPdf();
        for (let j = 1; j < this.domList.length - 1; j++) {
          doc = doc
            .get("pdf")
            .then(pdf => pdf.addPage())
            .from(this.domList[j])
            .toContainer()
            .toCanvas()
            .toPdf();
        }
        // 列印完成輸出done
        doc
          .save()
          .then(
            () => ((this.iconLoading = false), (this.iconLoading2 = false))
          );
      }
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    }
  }
};
</script>
<style scoped>
.qrbody {
  padding: 0.4rem;
  color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  border-color: #3e3e3e;
  border-width: 0.35rem;
}
</style>