<template>
  <div id="itemlist" class="overflow-auto" style="height: 800px;background:#f1f1f1;">
    <div v-if="showDetail" style="height: 1300px;"  >
      <a-spin size="large" :spinning="spinning"  tip="資料處理中..."> 
        <b-button  class="fontstyle1" variant="link" @click="toMaster()">
                  ( 回上一頁 )
        </b-button>
        <div style="padding:0.3rem;">
          <a-card title="" :bordered="false" style="width: 99.5%">
            <img
              slot="cover"
              alt="example"
              :src="fiData.url"
            />            
            <h5><strong><span v-text="fiData.actitle"></span></strong></h5>
            <div class="text-left" >              
              <div class="classempy">
                <b-icon icon="clock" variant="success"></b-icon> 活動時間 : <br/>
                <span style="font-size: 14px;" v-text="fiData.sdate  +' ~ ' + (fiData.edate==fiData.sdate?'':fiData.edate)"></span>
              </div>
              <div class="classempy">
                <b-icon icon="geo-alt" variant="success"></b-icon> 活動地點 : <br/>
                <span style="font-size: 14px;" v-text="fiData.address"></span>
              </div>  
              <a-checkbox @change="onChange">
                  全選/取消
              </a-checkbox>

            <div class="ccle">           
              <div style="width: 380px; border: 1px solid #d9d9d9; border-radius: 10px; padding:0.5em;margin-top:0.2em;margin-bottom: 0.3rem;" >
                  <a-calendar @select="onSelect" :defaultValue="currentTime" :locale="locale" :disabledDate="disabledDate" :fullscreen="true">                  
                      <div slot="dateCellRender" slot-scope="value" class="events" style="overflow-x: hidden;overflow-y:hidden;">
                          <div v-for="item  in getListData(value)" :key="item.id"  >
                            <div class="notes" :style="{'background-image': item.type==='Y'?'linear-gradient(to bottom, #c5ddf8, #3386e7)':'linear-gradient(to bottom, #dbdedb, #aeb1ae)','cursor':'pointer'}">   
                              <b-icon :icon=" item.type==='Y'? 'check2-circle':'bookmark-x'" style="margin-top:1.8rem;color:aliceblue;" font-scale="2" ></b-icon>                           
                            </div>
                          </div>
                      </div>                      
                  </a-calendar> 
              </div>  
              <div v-if="selectitem.length>0" style="width: 380px;padding:0.09rem;border-color:#d9d9d9;border-style: solid;border-width: 0.05em;border-top-left-radius:  15px;border-top-right-radius:  15px;">                    
                <ul class="rsgrid" style="margin-left: -1rem;">
                    <li v-for="item in selectitem" :key="item.id">
                      <div id="rcorners3">
                        <span  v-text="getdate(item.obj.sdate)+' '" />
                        <span  v-if="!disables" @click="removes(item.id)" v-text="'(刪除)'" style="cursor: pointer;" />
                      </div>                     
                    </li>
                </ul>
                <a-alert v-if="showmsg" :message="mms" type="success" show-icon />
                <b-button type="button" @click="applyForm"  squared variant="primary" :disabled="disables"  block>
                   <b-spinner v-if="disables" small type="grow"></b-spinner>
                   立即報名
                   <b-icon icon="arrow-up-circle-fill" aria-hidden="true"></b-icon>
                </b-button>
                 <div style="padding:0.05rem 0 0 0;" />             
              </div>
              <div style="padding:12.8rem 0 0 0;" />
            </div>         
            </div>
          </a-card>
        </div>
      </a-spin>        
    </div>

    <div style="height: 960px;" v-if="!showDetail">
      <div
        class="post-preview"
        v-for="(item, $index) in aclist"
        :key="$index"
        style="margin:0px 0px 5px 0px;"
      >
        <div>
            <div class="cards" :style="{'background-color': $index%2===0?'#549bed':'#ec8952'}">
              <img :src="item.url" alt="dog" />
              <div class="cards-details">
                <span v-text="item.actitle" class="fontstyle" />
                <br />
                <span v-text="'活動類別 : '+ getinfo(item.theme)" class="fontstyle" />
                <br />
                <span v-text="'活動日期 :'" class="fontstyle" />
                <br />
                <span v-html="fdateformat(item)" class="fontstyle" />
                <br />
                <b-button v-if="item.acts_actclass[0].checked==false" class="fontstyle1" variant="link" @click="toDetail(item)">
                  ( 報名 )
                </b-button>
                <span v-if="item.acts_actclass[0].checked" v-text="'該活動有人數限制'" class="fontstyle2" />
              </div>
            </div>
        </div>
      </div>
      <div style="padding:10.5rem 0 0 0;" />
      <infinite-loading @infinite="infiniteHandler" spinner="waveDots" :distance="5">
        <span slot="no-more">
          <a-spin tip>
            <div class="spin-content">資料已經到底了, 謝謝。。。</div>
          </a-spin>
        </span>
      </infinite-loading>
      <div style="padding:3.5rem 0 0 0;" />
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { actService, joinService} from "../../../_services";
import MY_JSON from "../../../assets/json/optiondata.json";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';
import InfiniteLoading from "vue-infinite-loading";

const queryACata = obj => {
  return actService.getactlistall(obj);
};  

const postdata = obj => {
  return  joinService.postformm(obj)
}


export default {
  name: "Viewper",
  components: {
    InfiniteLoading
  },
  props: {
    msg: String,
    item: Array,
  },
  data() {
    return {
      myJson: MY_JSON,
      showDetail: false,
      spinning:false,
      disables:false,
      showmsg:false,
      page: 1,
      selected :'all',
      aclist: [],
      fiData:null,
      currentTime:moment(),
      others:[],
      selectitem:[],
      mms:"資料新增成功",
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },      
    };
  },
  async mounted() {
  },
  computed: {
    ...mapState("account", ["user"])
  },

  methods: {
    fdateformat(val) {
      return (
        moment(val.sdate).format("YYYY-MM-DD HH:mm") +
        "~" +
        moment(val.edate).format("MM-DD HH:mm")
      );
    },
    catImg(imgs) {
      return require(`../../../assets/img/${imgs}`);
    },
    getinfo(theme) {
      let url = this.myJson.find(rs => rs.value == theme).title;
      return url;
    },

    getxt(val) {
      return val.substring(1, 32);
    },

    ontype(val) {
      this.selected = val;
      this.infiniteHandler();
    },

    toDetail(val){
        this.fiData = val
        this.selectitem =[]
        this.showDetail=true
    },

    toMaster(){
        this.showDetail=false
    },  

	async infiniteHandler($state) {
      let params = {
        page: this.page,
        size: 4,
        type: this.selected,
        states: "Y"
      };
      let rs=await this.getRuner(params)
      if(rs){
        $state.loaded();
      }else{
        $state.complete();
      }
    },

    getRuner(params) {
      return new Promise((resolve) => {
      this.page++;
      queryACata({
        ...params
      }).then(({ data }) => {
        let obj = {
          hits: data.data
        };
        if (obj.hits == null) {
          obj.hits = [];
        }
        if (obj.hits.length) {
          this.aclist.push(...obj.hits);
          resolve(true);
        } else {
          resolve(false);
        }
      });
      });
    },


    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },    

    getListData(value) {
      let listData=[];
      if(this.fiData.acts_actclass!==null){ 
        this.fiData.acts_actclass.forEach((item)=>{          
          if(moment(item.sdate).format('YYYY-MM-DD') == value.format("YYYY-MM-DD")){
              let state = moment(item.edate).format('YYYY-MM-DD HH:mm') >=this.currentTime.format("YYYY-MM-DD HH:mm")?'Y':'N'                             
              listData.push(
                  {
                    type:  state,
                    id:item.id,
                    fid:item.fid,          
                    orgid:item.orgid,       
                    url:item.url,           
                    actitle:item.actitle,          
                    sdate:item.sdate,         
                    edate:item.edate,                     
                    state:item.status                                                                                          
                }
              )
          }            
          });
          return listData || [];
      }
      return listData || [];

    },
    
    disabledDate(value){
      if(this.fiData.acts_actclass!==null){ 
        let state = ''
        this.fiData.acts_actclass.forEach((item)=>{          
          if(moment(item.sdate).format('YYYY-MM-DD') == value.format("YYYY-MM-DD")){
              state = moment(item.edate).format('YYYY-MM-DD HH:mm') >=this.currentTime.format("YYYY-MM-DD HH:mm")?'Y':'N'  
              return                           
          }            
          });
          return state=='N'? true:false  
      }  
    },       

    onChange(e){
      if(e.target.checked==true){
        let item = this.fiData.acts_actclass
        for (let i = 0; i < item.length; i++) {
          if(this.getformat(item[i].edate) > this.getformat(this.currentTime)){
            this.selectitem.push({obj:item[i],id:item[i].id,fid:this.fiData.id,orgid:this.fiData.orgid});
          }
        }
      }else{
        this.selectitem =[]
      }

    },  
    
    onSelect(e){
      let tmp = this.getdate(e._d);
      this.others=[]
        let obj = this.fiData.acts_actclass.filter(rs=>moment(rs.sdate).format("YYYY-MM-DD")==tmp)
        if(obj.length>0 && this.getformat(obj[0].edate) > this.getformat(this.currentTime)){          
            if(this.oncheck(obj[0].id).length==0){
              this.selectitem.push({obj:obj[0],id:obj[0].id,fid:this.fiData.id,orgid:this.fiData.orgid});
            }
        }    
    },
    
    oncheck(val){
      return this.selectitem.filter(rs=>rs.obj.id==val)
    },    

    getdate(val){
        return moment(val).format("YYYY-MM-DD"); 
    },      
    getformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },   
    removes(val) {
      console.log(val)
      this.selectitem = this.selectitem.filter(rs=>rs.id!=val)
    }, 
    
    showSuccess(){
      this.showmsg = true
      setTimeout(() => {  
        this.showmsg=false
        clearInterval();
        }, 2000);
    },
    
    async applyForm(){
      this.disables=true
      this.spinning=true
      let Tmps=[];
      let Join =[]
      for(var s in this.item){
        Tmps =[]
        for(var k in this.selectitem){
          Tmps.push({
            id : this.selectitem[k].id,
            fid : this.selectitem[k].fid,
            uid :  this.item[s].uid,     //申請者
            orgid : this.selectitem[k].orgid,
            sdate : this.selectitem[k].obj.sdate ,   // 舉辦日期
            edate : this.selectitem[k].obj.edate ,   // 結束日期
            states: 'N' ,   // 狀態
            roles : 'actor',    // 活動角色      
          })
        }
        Join.push({
          uid : this.item[s].uid,
          roles : 'actor',    // 活動角色  
          gedata : Tmps
        })
      }
      postdata(Join).then(({data}) =>{
          if(data!==null){
            data=="error"? this.mms="新增錯誤":"資料新增成功"
            data=="double"?this.mms="資料重覆新增":"資料新增成功"
            setTimeout(() => {  
              this.showSuccess()
              this.disables = false;
              this.spinning=false
              clearInterval();
              }, 3000);
          }
           
                      
      });        
    }

  },
  created() {},
  destroyed() {
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}

#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
}

img {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: min(98%, 500px);
  margin: 0 auto;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
  overflow: hidden;
}

.cards-details {
  position: relative;
  padding: 0 0.05rem 0 0.5rem;
}

.fontstyle {
  font-size: 0.8rem;
  color: #ffffff;
}

.fontstyle1 {
  font-size: 0.9rem;
  font-weight: bold;
  color: #091dd1;
}
.fontstyle2 {
  font-size: 0.8rem;
  color: hsl(145, 86%, 80%);
}

.spin-content {
  padding: 60px;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
}

.classempy{
  padding:0;
}

.ccle {
	margin: 0px auto;
}

.notes{
  position: absolute;
  border-radius: 15px;
  top: 25%;
  left: 0;
  width: 80%;
  height: 70%;
  text-align: center; /* 文本水平居中 */
  vertical-align: middle; /* 文本垂直居中 */  
  cursor:pointer;
  }


.rsgrid li { 
  display: inline-block;
	vertical-align: top;
  font-size:0.9em;
  line-height: 1.5em;
}

#rcorners3 {
  border-radius:  15px 15px;
  font-size:0.9em;
  text-align: center;
  color:#f7f5f0;
  background: #436b72;
  padding: 0.2em; 
  width: 110px;
  height: 25px; 
  margin: 0.18em;
} 
  
</style>
<style>
.ant-fullcalendar-header{
  padding:10px;
  margin-top:-10px;
}
.ant-fullcalendar-header .ant-radio-group {
    display: none !important;
}
.ant-fullcalendar-year-select{
    padding:5px;
}
.ant-fullcalendar-month-select{
      left: -3px;
}
.ant-fullcalendar{
  font-size:12px;
  font-weight: bold;
}
</style>





